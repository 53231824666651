import React from 'react'
import Layout from 'layouts/layout'
import Seo from 'components/EN/Seo'

const Pribacy = (props) => {
  return (
    <Layout>
      <Seo title="Privacy Policy" description="" keywords="" saTitle="" />
      <pre className="whitespace-pre-wrap px-[10px]">
        <div className="hidden-sm hidden-xs text-center mt-[20px]">Sensors Data Privacy Policy</div>
        The Privacy Policy takes effect on December 31, 2019
        <br />
        Sensors Data Co., Ltd (referred to as "Sensors Data" hereinafter), as the operator of the official website of
        Sensors Data, knows the importance of personal information to you. This privacy policy (referred to as the
        "policy" hereinafter) is formulated to remind you:
        <br />
        Please carefully read and thoroughly understand this policy before using the products or services of the
        official website of Sensors Data and use the relevant products or services after confirming your full
        understanding and consent. Once you click on the web page to confirm, purchase or begin to use the products or
        services of the official website of Sensors Data, you will be deemed to have fully understood and agreed to this
        policy, and agreed to collect, use, store, transmit, share and transfer your personal information according to
        this policy.
        <br />
        <b>Prompt Terms</b>
        <br />
        <b>Part I Definition</b>
        <br />
        Sensors Data official website: the website of Sensors Data network technology (Beijing) co., LTD. (domain name:
        sensorsdata.cn, sensorsdata.com) Personal information: refers to all kinds of information recorded
        electronically or otherwise that can identify the identity of a specifically natural person alone or in
        combination with other information or reflect the activities of a specifically natural person.
        <br />
        <b>Part II Privacy Policy</b>
        <br />
        This privacy policy will help you understand the following:
        <br />
        I. How does Sensors Data collect and use your personal information?
        <br />
        II. How Sensors Data retain your Personal Information?
        <br />
        III. How does Sensors Data use cookies and similar technologies?
        <br />
        IV. How does Sensors Data share, transfer and publicly disclose your personal information?
        <br />
        V. How does Sensors Data protect your personal information?
        <br />
        VI. How do you transfer your personal information globally?
        <br />
        VII. How does Sensors Data change the privacy statement?
        <br />
        VIII. How to contact Sensors Data?
        <br />
        <b>I. How does Sensors Data collect and use your personal information?</b> <br />
        Sensors Data collects and USES your personal information for the following purposes described in this policy:
        <br />
        i. To help you become a customer of Sensors Data
        <br />
        In order to become a customer of Sensors Data, so that Sensors Data can provide customer service for you or you
        can freely experience the Demo of Sensors Data and view data, you need to provide contact name, email and other
        information. If you only need to visit our official website, you do not need to register as our customer and
        provide the above information.
        <br />
        ii. Display and push products or services for you
        <br />
        In order to improve Sensors Data’s products or services and provide you with personalized email push service, if
        you do not want to accept the commercial ads sent to you by Sensors Data, you can unsubscribe by email reply or
        other means provided by Sensors Data.
        <br />
        iii. To provide you with products or services
        <br />
        1. Account registration function provided by Sensors Data:
        <br />
        When you apply to register for a Sensors Data account, you will offer the contact name, email and other
        information for registration. For the consideration of security, Sensors Data will send an email for
        verification to activate your account. After the activation, you can have your Sensors Data account to free
        trial demo, ref files &amp; reports, and more. When you forget the password of your Sensors Data account, please
        use the registered email to contact contact@sensorsdata.cn, and we will help you reset the password; when you
        need to cancel your Sensors Data account, please use the registered email to contact contact@sensorsdata.cn, and
        we will delete your account.
        <br />
        2. The information you provided to Sensors Data:
        <br />
        In order to provide you with the products or services of Sensors Data, Sensors Data will record the information
        you provide, such as the contact name, email, etc.
        <br />
        3. Information collected by Sensors Data during your use of the service:
        <br />
        When you contact Sensors Data, Sensors Data may keep information such as your correspondence/call records and
        contents or the contact information left by you, so as to contact you or help you solve the problem, or record
        the solutions and results of relevant problems.
        <br />
        iv. Other USES
        <br />
        Sensors Data will seek your prior consent to use the information for other purposes not specified in this
        policy, or to use the information collected for a specific purpose.
        <br />
        <b>II. How Sensors Data retain your Personal Information?</b>
        <br />
        We keep your personal information to enable your continued use of Sensors Data services, for as long as it is
        required in order to fulfill the relevant purposes described in this Privacy Policy, as may be required by law
        (including for tax and accounting purposes), or as otherwise communicated to you. How long we retain specific
        personal information varies depending on the purpose for its use, and we will delete your personal information
        in accordance with applicable law.
        <br />
        <b>III. How does Sensors Data use cookies and similar technologies?</b>
        <br />
        i. Cookies
        <br />
        In order to ensure the normal and efficient operation, the site for you to get easier access to experience, to
        recommend you might be interested in the content of the god's on your computer or mobile device storage
        information: this information may be a Cookie, Flash cookies, or your browser or associated applications
        provided by other local storage (generally referred to as the "Cookie").Please understand that some of our
        services can only be implemented by using cookies. If permitted by your browser or browser add-on services, you
        may modify your acceptance of cookies or reject Sensors Data 's cookies, but in some cases, the rejection of
        Sensors Data’s cookies may affect your safe access to the website and use of the services provided by Sensors
        Data.
        <br />
        ii. Web beacons and pixel labels
        <br />
        In addition to cookies, Sensors Data also USES similar technologies such as web site beacons and pixel tags on
        websites. For example, an email sent to you by Sensors Data may contain an address link to the content of the
        Sensors Data website. If you click on the link, Sensors Data will track the click to help Sensors Data
        understand your product or service preferences so that Sensors Data can proactively improve the customer service
        experience. If you do not wish your activities to be tracked in this way, you can always unsubscribe from
        Sensors Data 's mailing list.
        <br />
        <b>IV. How does Sensors Data share, transfer and publicly disclose your personal information?</b>
        <br />
        i. Sharing
        <br />
        Sensors Data will not share your personal information with third party companies, organizations or individuals,
        except:
        <br />
        1. Sharing with express consent: after obtaining your express consent, Sensors Data will share your personal
        information with other parties.
        <br />
        2. Sharing under legal circumstances: Sensors Data may share your personal information with others in accordance
        with laws and regulations, or as required by administrative and judicial authorities.
        <br />
        3. Sharing with authorized partners: for the purposes stated in this policy, some of Sensors Data 's services
        will be provided jointly by Sensors Data and authorized partners. Sensors Data may share some of your personal
        information with partners to provide better customer service and user experience. Sensors Data will only share
        your personal information for legitimate, legitimate, necessary, specific and specific purposes and will only
        share the personal information necessary to provide the service. Sensors Data’s partners have no right to use
        the Shared personal information for other purposes other than those related to the product or service.
        <br />
        With respect to the companies, organizations and individuals with whom Sensors Data shares personal information,
        Sensors Data will enter into strict data protection agreements with them, requiring them to handle personal
        information in accordance with Sensors Data 's instructions, this policy and any other relevant confidentiality
        and security measures.
        <br />
        i. assignment
        <br />
        Sensors Data will not transfer your personal information to any company, organization or individual, except:
        <br />
        1. Transfer with express consent: upon obtaining your express consent, Sensors Data will transfer your personal
        information to other parties.
        <br />
        2. In the case of merger, acquisition or bankruptcy liquidation, if the transfer of personal information is
        involved, Sensors Data will require the new company or organization holding your personal information to
        continue to be bound by this policy; otherwise, Sensors Data will require the company or organization to seek
        authorization from you again.
        <br />
        ii. public disclosure
        <br />
        Sensors Data will publicly disclose your personal information only if:
        <br />
        1. With your express consent or based on your active choice, Sensors Data may publicly disclose your personal
        information;
        <br />
        2. Sensors Data may publicly disclose your personal information when required by law, legal proceedings,
        litigation or government authorities.
        <br />
        iii. exceptions where prior authorization is required for the sharing, transfer or public disclosure of personal
        information
        <br />
        Sharing, transferring, or publicly disclosing your personal information does not require your prior permission
        in the following situations:
        <br />
        1. Those related to national security and national defense security
        <br />
        2. Those related to public safety, public health and major public interests.
        <br />
        3. Those related to criminal investigation, prosecution, trial and execution of judgment.
        <br />
        4. For the purpose of safeguarding your or other personal life, property and other major legal rights and
        interests, but it is difficult to obtain the consent of the person.
        <br />
        5. Personal information that you disclose to the public.
        <br />
        6. Collect personal information from legally disclosed information, such as legal news reports.
        <br />
        <br />
        Government information disclosure and other channels by law, sharing, transfer, publicly disclose the processing
        of personal information and ensure the data receiver cannot recover and identify personal information main body,
        does not belong to the foreign share, transfer and disclosure of personal information, the preservation and
        processing of such data will be without prior notice to you and ask for your consent.
        <br />
        V. How does Sensors Data protect your personal information?
        <br />
        <br />
        i. Sensors Data has taken reasonable and feasible security measures in line with industry standards to protect
        the security of your personal information and prevent unauthorized access, public disclosure, use, modification,
        damage or loss of your personal information. For example, the exchange of data between your browser and the
        server is protected by SSL (Secure Socket Layer) protocol encryption; Sensors Data also provides HTTPS (Hyper
        Text Transfer Protocol over Secure Socket Layer) Protocol for the official website of Sensors Data. Sensors Data
        will use encryption technology to improve the security of personal information.
        <br />
        ii. Sensors Data has an industry-advanced data security management system centering on the data life cycle,
        which can improve the security of the whole system from the aspects of organization construction, system design,
        personnel management, product technology, etc.
        <br />
        iii. In the event of a personal information security incident, Sensors Data will, in accordance with the
        requirements of laws and regulations, inform you of the basic situation of the security incident and the
        possible impact, the disposal measures Sensors Data has taken or will take, the Suggestions you can voluntarily
        prevent and reduce the risk, and the remedial measures for you. Sensors Data will take a reasonable and
        effective way to timely release the relevant information of the event to the public. At the same time, Sensors
        Data will also be in accordance with the requirements of the regulatory department, the initiative to report the
        disposal of personal information security incidents.
        <br />
        VI. How do you transfer your personal information globally?
        <br />
        Sensors Data is located in the China, and our services are located throughout the world. Depending on the scope
        of your interactions with Sensors Data Offerings, your personal information may be stored in or accessed from
        multiple countries, including the United States. Whenever we transfer personal information to other
        jurisdictions, we will ensure that the information is transferred in accordance with this Privacy Notice and as
        permitted by applicable data protection laws.
        <br />
        VII. How does Sensors Data change the privacy statement?
        <br />
        We may update this privacy statement from time to time to reflect changes in our data governance practices. The
        revised privacy statement will be published here and updated to date. We recommend that you check regularly for
        any changes or updates. If we make significant changes to our privacy statement, we will issue a 30-day notice
        at the top of this page. Continuing to use our website after the changes take effect will allow us to assume
        that you have read and understood the changes.
        <br />
        VIII. How to contact Sensors Data?
        <br />
        If you have any questions, comments or suggestions about the content of this policy, you can contact Sensors
        Data through:
        <br />
        Tel: 0086-4006509827
        <br />
        Email: mkt@sensorsdata.com
        <br />
      </pre>
    </Layout>
  )
}

export default Pribacy
